.ql-hidden {
  display: none;
}

.ql-editor {
  padding-top: 0;
  padding-bottom: 0;
}

.ql-disabled > .ql-editor {
  padding-left: 0;
  padding-right: 0;
}

.scrollable-content {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Insaniburger';
  src: local('Insaniburger'), url('./fonts/Insanibu.ttf') format('truetype');
}

body {
  overscroll-behavior: none;
}

.slick-dots .ft-slick__dots--custom {
  background-color: lightGrey;
  width: 6px;
  height: 6px;
  border-radius: 1000px;
  transition: width 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 30px;
  background: linear-gradient(135deg, #ec407a, #ab47bc);
  transition: width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-dots li {
  width: 6px !important;
}

.slick-dots .slick-active {
  width: 30px !important;
}

.slick-slide div {
  outline: none;
}
